import { BrowserRouter as Router, Route,  Routes } from "react-router-dom";
import QuizForm from "./QuizForm";
import Quiz from "./Quiz";

function MyRoutes() {
  return (

    <Router>
            <Routes>
              <Route exact path="/" element={<QuizForm />}/>
              <Route exact path="/quiz" element={<Quiz />}/>
             
            </Routes>
           
          </Router>
      );
    }
    
    export default MyRoutes;
