import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from 'axios';



const questions = [
  {
    question: "1.	Which of the following is the CGNET rockstar package?",
    options: ["150 Mbps", "250 Mbps", "350 mbps", "130 mbps"],
    answer: "350 mbps",
  },
  {
    question: "2.	What is the price of the most popular CGNET package?",
    options: ["1249", "1049", "949", "1149"],
    answer: "1249",
  },
  {
    question: "3.	In which plan you can Use First, Pay Later?",
    options: ["Super value Plan", "Postpaid Plan", "Refer Plan", "Jodi Plan"],
    answer: "Postpaid Plan",
  },
  {
    question: "4.	Which of the following is the feature of digital well being ?",
    options: ["Child safety", "Family time", "Study hour", "All of the above"],
    answer: "All of the above",
  },
  {
    question: "5.	What is the tagline of CGNET ?",
    options: ["Speed that matters", "Super high speed", "Connect to the best", "Get connected with us"],
    answer: "Speed that matters",
  },
];






function Quiz() {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [selectedOption, setSelectedOption] = useState("");
  const [score, setScore] = useState(0);
  const [showScore, setShowScore] = useState(false);
  const [startTime, setStartTime] = useState(0);
  const [endTime, setEndTime] = useState(0);
  const [timeTaken, setTimeTaken] = useState(0);
  const [apidata, setApidata] = useState([]);


  useEffect(() => {
    setStartTime(new Date().getTime());

    setInterval(function () {
    axios.get('https://bknd.cgnet.com.np/getnewYear', {
      headers: {
        "Content-Type": "application/json"
      }

    })
      .then(response => {
        console.log("get", response.data);
        setApidata(response.data)

      })
      .catch(error => {
        console.log(error);

      });
    }, 1500);
  }, []);



  useEffect(() => {
    const usera = localStorage.getItem("usera");

    console.log(score);
    if (showScore === true) {
      setTimeout(function () {
        axios
          .post(
            "https://bknd.cgnet.com.np/newYear",
            {
              userDetails: usera,
              score: String(score),
              time: String(timeTaken),
            },
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          )
          .then((response) => {
            console.log("apidata", response.data);
          })
          .catch((error) => {
            console.log(error);
          });
      }, 500);
    }
  }, [showScore])



  const handleOptionChange = (option) => {
    setSelectedOption(option);
  };
  console.log(score);

  const handleNextClick = () => {


    if (selectedOption === questions[currentQuestion].answer) {
      setScore(score + 1);
    }
    setSelectedOption("");
    if (currentQuestion === questions.length - 1) {
      const endTime = new Date().getTime();
      const timeTaken = Math.floor((endTime - startTime) / 1000);
      setTimeTaken(timeTaken);

      setShowScore(true);

    } else {
      setCurrentQuestion(currentQuestion + 1);
    }
  };
  const navigate = useNavigate();

  const handleRestartClick = () => {
    // localStorage.removeItem("usera")
    setCurrentQuestion(0);
    setSelectedOption("");
    setScore(0);
    setShowScore(false);
    setStartTime(new Date().getTime());
    window.top.location.href = "https://cgnet.com.np";

  };


  if (!localStorage.getItem("usera")) {
    navigate("/");
    return null;
  }

  const user = JSON.parse(localStorage.getItem("usera"));


  return (
    <div className="quiz-container">
      <div className="quiz-header">
        <h2>Welcome, {user.name}!</h2>
        {/* <p>Contact No.: {user.contactNo}</p>
  <p>Email Address: {user.email}</p>
  <p>Address: {user.address}</p> */}
      </div>
      {showScore ? (
        <div className="quiz-score">
          <h2>Your Score: {score}/{questions.length}</h2>
          <p>Time Taken: {timeTaken} seconds</p>
          <button onClick={handleRestartClick}>Go Home</button>
        </div>
      ) : (
        <div className="quiz-question">
          <h2>{questions[currentQuestion].question}</h2>
          <div className="quiz-options">
            {questions[currentQuestion].options.map((option) => (
              <div key={option}>
                <input
                  type="radio"
                  id={option}
                  name="option"
                  value={option}
                  checked={selectedOption === option}
                  onChange={() => handleOptionChange(option)}
                />
                <label htmlFor={option}>{option}</label>
              </div>
            ))}
          </div>
          <button onClick={handleNextClick} disabled={!selectedOption}>
            Next
          </button>
        </div>
      )}



      <div className="scoreboard">
        <h2>Scoreboard</h2>
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>User Details</th>
              <th>Score</th>
              <th>Time</th>
            </tr>
          </thead>
          <tbody>
            {apidata
              .sort((a, b) => a.time - b.time)
              .map((item, index) => (
                <tr key={index}>
                  <td>{item.id}</td>
                  <td>{JSON.parse(item.userDetails).name}</td>
                  <td>{item.score}</td>
                  <td>{item.time}</td>
                </tr>
              ))}
          </tbody>
        </table>

      </div>
    </div>
  );
}

export default Quiz;
