import logo from './logo.svg';
import './App.css';
import ReactDOM from "react-dom";
import MyRoutes from "./Routes";

function App() {
  return (
    <div className="App">
      <div>
        {/* <img src='/memonic.png'  className='memonic'/> */}
        <img src='/3000by400.jpg' className='banner'/>
        <div className='resBanner'>
           <img src='/mobileRes.png' />
        </div>
      </div>
    <div class="wrapperOne">
      <div>
       <img src='/300by900.jpg' className='sideBanner'/>
      </div>
    <MyRoutes />
    <div>
       <img src='/300by900.jpg' className='sideBanner'/>
    </div>
    </div>
    </div>
  );
}

export default App;
