import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Quiz from "./Quiz";

function QuizForm() {
    const [name, setName] = useState("");
    const [contactNo, setContactNo] = useState("");
    const [email, setEmail] = useState("");
    const [address, setAddress] = useState("");
    const [final, setFinal] = useState(null);
    const [error, setError] = useState(false);
    const [contError,setContError] = useState(false)
    
    const navigate = useNavigate();




    useEffect(() => {
        const check = localStorage.getItem("usera");
        if (!check) {
            localStorage.setItem("usera", '{"user":"a","contactNo":"0","email":"xxx@gmail.com"}');
        }
    }, [])

    const handleSubmit = (e) => {
        e.preventDefault();
        setError(false);
        setContError(false);
       
        // save user details to local storage
        const user = { name, contactNo, email, address };
      
        const validate = JSON.parse(localStorage.getItem("usera")).contactNo;


        if (user.contactNo === validate) {
            setError(true);
        } 
        else if(user.contactNo.length < 8){
            setContError(true);
        }else {
            localStorage.setItem("usera", JSON.stringify(user));
            setFinal(user);
            // redirect to quiz page
            navigate("/");
        }

    };
    const changeHandler = (e) => {
        setContactNo(e.target.value);
        console.log(e.target.value.length)

    }

    return (
        <div className="form-container">
            {final === null ? <h2>Please fill the form below to continue.</h2> : null}
            {error ? <div className="error">Contact number already registered use different number.</div> : null}
            {contError ? <div className="error">Contact number should not be less than 8 digits.</div> : null}
            
            {final ? (
                <Quiz name={name} contactNo={contactNo} email={email} address={address} />
            ) : (
                <form onSubmit={handleSubmit}>

                    <label>
                        Name:
                        <input
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            required
                        />
                    </label>
                    <label>
                        Contact No.:
                        <input
                            type="number"
                            value={contactNo}
                            onChange={changeHandler}
                            required
                        />
                    </label>
                    <label>
                        Email Address:
                        <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </label>
                    <label>
                        Address:
                        <textarea
                            value={address}
                            onChange={(e) => setAddress(e.target.value)}
                            required
                        />
                    </label>
                    <button type="submit">Start Quiz</button>
                </form>
            )}
        </div>
    );
}

export default QuizForm;
